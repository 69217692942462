import React from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import '../navbar/navbar.scss';
import './footer.scss';
import termsOfUse from '../../assets/ratingringsterms.docx';

function Footer() {
  const createDate = new Date();
  const date = createDate.getFullYear();

  const downloadFile = lang => {
    const link = document.createElement('a');
    link.href = termsOfUse;
    link.download = 'terms_of_use.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <footer>
      <div className='container'>
        <div className='infoFooterTop'>
          <ul>
            <li>
              <p className='title'>
                <span>Pages</span>
              </p>
            </li>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/movie'>Movies</Link>
            </li>
            <li>
              <Link to='/show'>Tv Movies</Link>
            </li>
            <li>
              <Link>Sign In</Link>
            </li>
          </ul>
          <ul>
            <li>
              <p className='title'>
                <span>Help</span>
              </p>
            </li>
            <li onClick={downloadFile} className='termsBtn'>
              {/* <a href='#' target='_blank'> */}
              Terms Of Use
              {/* </a> */}
            </li>
            <li>
              <a href='https://t.me/ratingrings' target='_blank'>
                Help Center
              </a>
            </li>
            <li>
              <a href='https://t.me/ratingrings' target='_blank'>
                FAQ
              </a>
            </li>
          </ul>
        </div>
        <div className='infoFooterBottom'>
          <p>
            <span>
              <AiOutlineWarning />
            </span>{' '}
            I do not guarantee the accuracy of the information provided in the
            films.
          </p>
          <p>
            Copyright © {date}. All Rights Reserved By <span>Rating Rings</span>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
