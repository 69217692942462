import React from 'react'
import "./image.scss"

function Image() {
  return (
    <div className='image-load'>

    </div>
  )
}

export default Image